<template>
  <div>
    <v-sheet height="100">
      <v-container>
        <v-row>
          <!-- filter by teacher -->
          <v-col cols="12" lg="6">
            <v-select
              hide-details
              outlined
              label="Selected teacher"
              dense
              :items="teachers"
              v-model="teacherPublicKey"
              @input="getClassroomsTimes"
              clearable
              :item-text="
                (item) =>
                  `${item.fullName} | ${item.nickName}| ${
                    item.state
                  } | ${item.baseType.map((t) => $_t(`attribute.${t}`))}`
              "
              item-value="publicKey"
            >
              <template v-slot:prepend-item>
                <div class="px-3 py-2">
                  <v-text-field
                    outlined
                    :label="$_t('attribute.search')"
                    dense
                    hide-details
                    @input="initTeachers"
                    v-model="searchTeacher"
                  />
                </div>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              hide-details
              outlined
              label="Filter teachers"
              dense
              multiple
              :items="teachers"
              v-model="teacherPublicKeys"
              @change="initTimes({})"
              clearable
              :item-text="
                (item) =>
                  `${item.fullName} | ${item.nickName}| ${
                    item.state
                  } | ${item.baseType.map((t) => $_t(`attribute.${t}`))}`
              "
              item-value="publicKey"
            >
              <template v-slot:prepend-item>
                <div class="px-3 py-2">
                  <v-text-field
                    outlined
                    :label="$_t('attribute.search')"
                    dense
                    hide-details
                    @input="initTeachers"
                    v-model="searchTeacher"
                  />
                </div>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <EventsStateColor :show-new="false" />
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet height="500">
      <!--
 @mousedown:event="startDrag"
        @mousedown:time="startTime"
        @mousemove:time="mouseMove"
        @mouseup:time="endDrag"
        @mouseleave.native="cancelDrag"
        -->
      <v-calendar
        ref="calendar"
        v-model="focus"
        color="primary"
        @change="updateRange"
        :events="mixEvents"
        :locale="$i18n.locale"
        :event-color="getEventColor"
        type="week"
      >
        <template v-slot:event="{ event, timed, eventSummary }">
          <div style="height: 90%">
            <div class="v-event-draggable">
              <div v-if="event.classroomNumber">
                {{ `${event.classroomNumber}` }}
              </div>
            </div>
            <div
              class="v-event-draggable"
              v-if="event.lesson || event.baseType"
            >
              {{
                `${
                  event?.lesson.map((l) => `${$_t(`attribute.${l}`)}`) ||
                  event?.baseType.map((l) => `${$_t(`attribute.${l}`)}`)
                }`
              }}
              <br />
              <div v-if="event.teacherName">
                {{ `${$_t("attribute.teacher")}: ${event.teacherName}` }}
              </div>
            </div>
            <div class="v-event-draggable">
              <component :is="{ render: eventSummary }"></component>
            </div>
          </div>
          <div
            v-if="timed && typeof event.availableTimeId === 'number'"
            class="v-event-drag-bottom"
            @mousedown.stop="extendBottom(event)"
          ></div>
        </template>
      </v-calendar>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import message from "@/tools/Message";
import EventsStateColor from "@/packages/admin/components/schedule/EventsStateColor";
import { classStates } from "@/packages/admin/schema/CLASS_STATES";
import { getMainTime } from "@/tools/Utils";
import { event_colors } from "@/packages/admin/schema/calender/COLORS";
export default {
  name: "TeacherTimes",
  components: { EventsStateColor },
  computed: {
    ...mapGetters({
      allEvents: "class/getTeacherEvents",
      teacherTimes: "class/teacherEvents",
      teachers: "admin/get_teachers",
      times: "class/getTimesAvailableTimeId",
      timesObject: "class/getTimes",
      baseType: "class/getBaseType",
      classNumbers: "class/classroomNumbers",
    }),
    isSelectTeacher() {
      return !(
        !this.teacherPublicKey ||
        !this.teacherPublicKeys.includes(this.teacherPublicKey)
      );
    },
    teacher() {
      return (
        this.teachers.find((t) => t.publicKey === this.teacherPublicKey) || null
      );
    },
    teacherPublicKey: {
      get() {
        return this.$store.getters["class/getTeacher"] || null;
      },
      set(value) {
        this.$store.commit("class/updateTeacher", value);
      },
    },
    mixEvents() {
      return [...this.events, ...this.allEvents];
    },
  },
  data() {
    return {
      getMainTime,
      focus: "",
      time: null,
      classModal: false,
      classroomNumber: "",
      startWeek: null,
      endWeek: null,
      teacherPublicKeys: [],
      searchTeacher: "",
      timeState: null,
      classStates,
      events: [],
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      event_colors,
      selectedEvent: null,
      selectedElement: null,
      selectedOpen: false,
    };
  },
  methods: {
    addSessions(times) {
      this.events = times;
    },
    test() {
      let min = Number.POSITIVE_INFINITY;
      this.events.forEach((e) => {
        let tmp = e.start;
        if (tmp < min) min = tmp;
      });
      console.log(min, "min in events");
      console.log(this.teacherTimes, "teachers events");
      console.log(
        this.teacherTimes.filter((t) => t.end > min),
        "teachers events after filter"
      );
      let events = [];
      this.teacherTimes
        .filter((t) => t.end > min)
        .forEach((tt) => {
          for (const event of this.events) {
            if (event.start >= tt.start && event.end <= tt.end) {
              events.push({
                classroomNumber: event.classroomNumber,
                end: event.end / 1000,
                start: event.start / 1000,
                availableTimeId: tt.availableTimeId,
                message: null,
              });
            }
          }
        });
      console.log(events);
    },
    getEvents() {
      if (!this.events.length) {
        // message.error("Please select some events");
        return [];
      }
      if (this.events.find((e) => !e.classroomNumber)) {
        message.error("Please select some room");
        return false;
      }

      let min = Number.POSITIVE_INFINITY;
      this.events.forEach((e) => {
        let tmp = e.start;
        if (tmp < min) min = tmp;
      });

      let events = [];
      this.teacherTimes
        .filter((t) => t.end > min)
        .forEach((tt) => {
          for (const event of this.events) {
            if (event.start >= tt.start && event.end <= tt.end) {
              events.push({
                classroomNumber: event.classroomNumber,
                end: event.end / 1000,
                start: event.start / 1000,
                availableTimeId: tt.availableTimeId,
                message: null,
              });
            }
          }
        });
      return events;
    },
    async updateRange({ start, end }) {
      const viewStart = this.$refs.calendar.getStartOfWeek(start);
      const startDate = new Date(viewStart.date).getTime();
      const viewEnd = this.$refs.calendar.getEndOfWeek(end);
      const endDate = new Date(viewEnd.date).getTime();
      this.startWeek = startDate / 1000;
      this.endWeek = endDate / 1000;
      await this.initTimes();
      await this.getClassroomsTimes();
    },
    async initTimes() {
      // get teachers timetable

      await this.$store.commit("class/clearTimes");
      await this.$actions.getTeacherTimetable({
        all: true,
        role: "Admin",
        dispatch: "class/setTeacherEvents",
        publicKey: this.teacherPublicKeys,
        // start: this.startWeek,
        // end: this.endWeek, TODO
      });
    },
    async getClassroomsTimes() {
      await this.$actions.getClassroomsTimes({
        teacherPublicKey: this.teacherPublicKey,
      });
    },
    async initTeachers() {
      await this.$actions.getUsersByRoleAndBaseType({
        role: ["Teacher"],
        dispatch: "admin/setTeachers",
        search: this.searchTeacher,
        baseType: this.baseType ? [this.baseType] : null,
      });
    },
    getEventColor(event) {
      return event.color;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    setToday() {
      this.focus = "";
    },
    getTimeObject(id) {
      return this.timesObject.find((t) => t.availableTimeId === id);
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      if (!this.isSelectTeacher)
        return message.error("Please select and filter by teacher");

      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        this.createStart = this.roundTime(mouse);
        this.createEvent = {
          color: this.event_colors.new,
          start: this.createStart,
          end: this.createStart,
          availableTimeId: this.generateId(),
          timed: true,
        };

        this.events.push(this.createEvent);
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        if (typeof this.dragEvent.availableTimeId === "string") return;
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        if (typeof this.createEvent.availableTimeId === "string") return;
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    endDrag() {
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }
      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
  },
  async mounted() {
    await this.$actions.getClassNumbers();
    await this.initTeachers();
  },
};
</script>

<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}
</style>
