<template>
  <div class="d-flex justify-start flex-fill px-4">
    <span v-if="showNew" class="d-flex align-center flex-row mr-9">
      <v-sheet
        width="15px"
        height="15px"
        :color="event_colors.new"
        class="rounded-pill mr-2"
      ></v-sheet>
      {{ $_t("attribute.new") }}
    </span>
    <span class="d-flex align-center flex-row mr-9">
      <v-sheet
        width="15px"
        height="15px"
        :color="event_colors.have_class"
        class="rounded-pill mr-2"
      ></v-sheet>
      {{ $_t("attribute.have_class") }}
    </span>

    <span class="d-flex align-center flex-row mr-9">
      <v-sheet
        width="15px"
        height="15px"
        :color="event_colors.waiting_for_class"
        class="rounded-pill mr-2"
      ></v-sheet>
      {{ $_t("attribute.waiting_for_class") }}
    </span>

    <span class="d-flex align-center flex-row mr-9">
      <v-sheet
        width="15px"
        height="15px"
        :color="event_colors.cancel"
        class="rounded-pill mr-2"
      ></v-sheet>
      {{ $_t("attribute.canceled") }}
    </span>

<!--    <span class="d-flex align-center flex-row mr-9">-->
<!--      <v-sheet-->
<!--        width="15px"-->
<!--        height="15px"-->
<!--        :color="event_colors.full"-->
<!--        class="rounded-pill mr-2"-->
<!--      ></v-sheet>-->
<!--      {{ $_t("attribute.full") }}-->
<!--    </span>-->
  </div>
</template>

<script>
import { event_colors } from "@/packages/admin/schema/calender/COLORS";

export default {
  name: "EventsStateColor",
  props: {
    showNew: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      event_colors,
    };
  },
};
</script>

<style scoped></style>
