<template>
  <div>
    <ClassCreate />
  </div>
</template>

<script>
import ClassCreate from "@/packages/admin/components/classRoom/ClassCreate";
export default {
  name: "classRoomCreate",
  components: { ClassCreate },
};
</script>

<style scoped></style>
