import { _t } from "@/tools/Utils";
// must update teacher fee
export const levels = [
  { text: _t("attribute.All"), value: "All" },
  { text: _t("attribute.GCSE"), value: "GCSE" },
  { text: _t("attribute.A_LEVEL"), value: "A_LEVEL" },
  { text: _t("attribute.KS_3"), value: "KS_3" },
  { text: _t("attribute.English_Elementary"), value: "English_Elementary" },
  {
    text: _t("attribute.AdditionalExamination"), //Test_Competition
    value: "AdditionalExamination",
  },
  { text: _t("attribute.University"), value: "University" },
  { text: _t("attribute.Contest"), value: "Contest" },
  { text: _t("attribute.IELTS"), value: "IELTS" },
  { text: _t("attribute.TOEFL"), value: "TOEFL" },
  { text: _t("attribute.IB"), value: "IB" },
  { text: _t("attribute.AP"), value: "AP" },
  { text: _t("attribute.SAT"), value: "SAT" },
  { text: _t("attribute.Other"), value: "Other" },
];

export const levels_filter = [
  { text: _t("attribute.All"), value: null },
  { text: _t("attribute.GCSE"), value: "GCSE" },
  { text: _t("attribute.A_LEVEL"), value: "A_LEVEL" },
  { text: _t("attribute.KS_3"), value: "KS_3" },
  { text: _t("attribute.English_Elementary"), value: "English_Elementary" },
  {
    text: _t("attribute.AdditionalExamination"), //Test_Competition
    value: "AdditionalExamination",
  },
  { text: _t("attribute.University"), value: "University" },
  { text: _t("attribute.Contest"), value: "Contest" },
  { text: _t("attribute.IELTS"), value: "IELTS" },
  { text: _t("attribute.TOEFL"), value: "TOEFL" },
  { text: _t("attribute.IB"), value: "IB" },
  { text: _t("attribute.AP"), value: "AP" },
  { text: _t("attribute.SAT"), value: "SAT" },
  { text: _t("attribute.Other"), value: "Other" },
];

export const fee_levels = [
  { text: _t("attribute.GCSE"), value: "GCSE" },
  { text: _t("attribute.A_LEVEL"), value: "A_LEVEL" },
  { text: _t("attribute.KS_3"), value: "KS_3" },
  { text: _t("attribute.English_Elementary"), value: "English_Elementary" },
  {
    text: _t("attribute.AdditionalExamination"), //Test_Competition
    value: "AdditionalExamination",
  },
  { text: _t("attribute.University"), value: "University" },
  { text: _t("attribute.Contest"), value: "Contest" },
  { text: _t("attribute.IELTS"), value: "IELTS" },
  { text: _t("attribute.TOEFL"), value: "TOEFL" },
  { text: _t("attribute.IB"), value: "IB" },
  { text: _t("attribute.AP"), value: "AP" },
  { text: _t("attribute.SAT"), value: "SAT" },
  { text: _t("attribute.Other"), value: "Other" },
];

export const testTargets = [
  { text: _t("attribute.Exam"), value: "Exam" },
  { text: _t("attribute.LevelEvaluation"), value: "LevelEvaluation" },
];
