import { lessons_array } from "@/packages/admin/schema/calender/LESSONS_ARRAY";
import { levels } from "@/packages/admin/schema/LEVELS";
import { _t } from "@/tools/Utils";

const { required } = require("vuelidate/lib/validators");

export const CREATE_CLASS_SCHEMA = {
  /* Form SCHEMA */
  schema: [
    {
      id: "teachingType",
      label: "teachingType",
      placeholder: "",
      cols: 12,
      lg: 4,
      md: 12,
      sm: 12,
      type: "select",
      show: true,
      value: "value",
      items: [
        {
          text: _t("attribute.offline"),
          value: "Offline",
        },
        {
          text: _t("attribute.online"),
          value: "Online",
        },
      ],
    },
    {
      id: "classroomLevel",
      label: "classroomLevel",
      placeholder: "",
      cols: 12,
      show: true,
      lg: 4,
      md: 12,
      sm: 12,
      type: "select",
      value: "value",
      items: [...levels],
    },
    {
      id: "baseType",
      label: "baseType",
      placeholder: "",
      show: true,
      cols: 12,
      lg: 4,
      md: 12,
      sm: 12,
      value: "value",
      text: "name",
      type: "select",
      items: [...lessons_array],
    },
  ],

  /* Form MODEL */
  model: {
    teachingType: null,
    classroomLevel: null,
    baseType: null,
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      teachingType: {
        required,
      },
      classroomLevel: { required },
      baseType: { required },
    },
  },
};
