<template>
  <div>
    <v-row class="justify-space-between">
      <v-col cols="6" sm="4" class="pt-5">
        <TopTitle :title="$_t('admin.components.class_table.create')" />
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <!--  info  -->
    <v-row>
      <v-col cols="12" class="px-0">
        <MainSoftForm>
          <template v-slot:body>
            <MyFormGenerator
              lg="10"
              :schema="schema"
              :validator="$v"
            ></MyFormGenerator>
          </template>
        </MainSoftForm>
      </v-col>
    </v-row>
    <!--  students and room times  -->
    <v-row>
      <v-col cols="12">
        <StudentsTimes ref="st" />
      </v-col>
    </v-row>
    <!--   calender actions -->
    <v-row>
      <v-col cols="12">
        <v-sheet class="p-5">
          <v-toolbar flat>
            <v-btn color="primary" class="px-0 mr-3" @click="prev">
              <v-icon large> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              {{ $_t("attribute.today") }}
            </v-btn>
            <v-btn color="primary" class="px-0 mr-4" @click="next">
              <v-icon large> mdi-chevron-right </v-icon>
            </v-btn>
            <!--            <v-spacer />-->
            <v-toolbar-title v-if="$refs.st">
              {{ $refs.st.getTitle }}
            </v-toolbar-title>
          </v-toolbar>
        </v-sheet>
      </v-col>
    </v-row>
    <!--  teacher times  -->
    <v-row>
      <v-col cols="12">
        <TeacherTimes ref="tt" />
      </v-col>
    </v-row>
    <!--  lessons  -->
    <v-row>
      <v-col cols="12">
        <LessonsList ref="ll" />
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" md="6">
            <v-btn
              color="primary"
              :disabled="loading"
              block
              @click="addClass"
              >{{ $_t("attribute.addClass") }}</v-btn
            >
          </v-col>
          <v-col cols="12" md="6">
            <v-btn
              outlined
              @click="$router.push({ name: 'admin.class_room' })"
              block
              color="primary"
              >{{ $_t("attribute.cancel") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TopTitle from "@/components/app/TopTitle";
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { CREATE_CLASS_SCHEMA } from "@/packages/admin/schema/classRoom/CREATE_CLASS.schema";
import StudentsTimes from "@/packages/admin/components/classRoom/StudentsTimes";
import TeacherTimes from "@/packages/admin/components/classRoom/TeacherTimes";
import LessonsList from "@/packages/admin/components/classRoom/LessonsList";
import { mapGetters } from "vuex";
import { getMainTime } from "@/tools/Utils";
import message from "@/tools/Message";

export default {
  name: "ClassCreate",
  components: {
    LessonsList,
    TeacherTimes,
    StudentsTimes,
    MyFormGenerator,
    MainSoftForm,
    TopTitle,
  },
  data() {
    return {
      schema: CREATE_CLASS_SCHEMA.schema,
      form: CREATE_CLASS_SCHEMA.model,
      getMainTime,
    };
  },
  watch: {
    "form.baseType"(val) {
      console.log(val);
      if (val !== "any") this.$store.commit("class/setBaseType", val);
      else this.$store.commit("class/setBaseType", null);
      this.$refs.tt.initTeachers();
      // this.$refs.tt.initTimes();
      this.$refs.ll.initSpecifications();
      this.$refs.ll.initLessons({});
    },
  },
  computed: {
    ...mapGetters({
      times: "class/getTimes",
      loading: "loading/is_loading",
    }),
  },
  methods: {
    clear() {
      //this.form.baseType = null;
      //this.form.classroomLevel = null;
      //this.form.teachingType = null;
      this.$store.commit("class/clearForm");
    },
    prev() {
      this.$refs.st.prev();
      this.$refs.tt.prev();
    },
    next() {
      this.$refs.st.next();
      this.$refs.tt.next();
    },
    setToday() {
      this.$refs.st.setToday();
      this.$refs.tt.setToday();
    },
    async addClass() {
      if (!this.isFormValidate()) return;
      const error = this.$store.getters["class/getFormValid"];
      if (error) return message.error(error);

      let form = this.$store.getters["class/getForm"];
      const times = this.$refs.tt.getEvents();
      if (!times) return;
      console.log({ ...form, ...this.form, times });
      const res = await this.$actions.createClass({
        ...form,
        ...this.form,
        times,
      });
      if (res) {
        this.clear();
        await this.$refs.tt.initTimes();
        await this.$refs.tt.getClassroomsTimes();
        await this.$refs.st.initTimes();
        await this.$refs.st.getNotAvailable();
        //await this.$router.push({ name: "admin.class_room" });
      }
    },
  },
  validations: {
    ...CREATE_CLASS_SCHEMA.validations,
  },
  destroyed() {
    this.clear();
  },
};
</script>

<style scoped></style>
