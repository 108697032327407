<template>
  <div>
    <v-sheet height="100">
      <v-container>
        <v-row>
          <v-col cols="12" md="2">
            <span class="d-flex align-center">
              <v-sheet
                width="15px"
                height="15px"
                color="#65635D"
                class="rounded-pill mr-2"
              ></v-sheet>
              {{ $_t("attribute.not_available") }}
            </span>
          </v-col>
          <v-col cols="12" md="3">
            <span class="d-flex align-center">
              <v-sheet
                width="15px"
                height="15px"
                :color="event_colors.canceled"
                class="rounded-pill mr-2"
              ></v-sheet>
              {{ $_t("attribute.students_not_available") }}
            </span>
          </v-col>

          <v-col cols="12" lg="7">
            <v-select
              hide-details
              outlined
              :label="$_t('attribute.students')"
              dense
              multiple
              :items="students"
              v-model="selectedStudents"
              clearable
              :item-text="(item) => `${item.fullName} | ${item.nickName}`"
              @input="initTimes"
              return-object
            >
              <template v-slot:prepend-item>
                <div class="px-3 py-2">
                  <v-text-field
                    outlined
                    :label="$_t('attribute.search')"
                    dense
                    hide-details
                    @input="initStudents({ search: $event })"
                  />
                </div>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <div class="d-flex flex-wrap justify-start px-5">
            <span
              v-for="student in selectedStudents"
              :key="student.publicKey"
              class="d-flex align-center flex-row mr-9"
            >
              <v-sheet
                width="15px"
                height="15px"
                color="#3060DD"
                class="rounded-pill mr-2"
              ></v-sheet>
              {{ `${student.fullName} ${student.nickName}` }}
            </span>
          </div>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet height="500">
      <v-calendar
        ref="studentCalendar"
        v-model="focus"
        :locale="$i18n.locale"
        color="primary"
        :events="studentsEvents"
        :event-color="getEventColor"
        type="week"
      >
        <template v-slot:event="{ event, eventSummary }">
          <div @click="eventDetails(event)" style="height: 100%">
            <div>
              {{ `${$_t("attribute.room")}: ${event.classroomNumber}` }}
            </div>
            <div v-if="event">
              {{ `${$_t("attribute.teacher")}: ${event.teacherName}` }}
            </div>
            <div v-if="event">
              {{
                `${$_t("attribute.baseType")}: ${$_t(
                  `attribute.${event.baseType}`
                )}`
              }}
            </div>
            <div class="v-event-draggable">
              <component :is="{ render: eventSummary }"></component>
            </div>
          </div>
        </template>
      </v-calendar>
      <v-dialog v-model="showDetails" width="1000">
        <v-card v-if="event">
          <v-container>
            <v-row>
              <v-col col="12" md="5" class="py-0 px-0">
                <v-card-title>
                  {{
                    `${$_t("attribute.baseType")}: ${$_t(
                      `attribute.${event.baseType || event.classroomBaseType}`
                    )}`
                  }}
                </v-card-title>
              </v-col>
              <v-col col="12" md="4" class="py-0 px-0">
                <v-card-title>
                  {{ `${$_t("attribute.teacher")}: ${event.teacherName}` }}
                </v-card-title>
              </v-col>
              <v-col col="12" md="3" class="py-0 px-0">
                <v-card-title>
                  {{ `${$_t("attribute.room")}: ${event.classroomNumber}` }}
                </v-card-title>
              </v-col>
              <v-col col="12" md="6" class="py-0 px-0">
                <v-card-title>
                  {{
                    `${$_t("attribute.start")}: ${$_date(event.start, "long")}`
                  }}
                </v-card-title>
              </v-col>
              <v-col col="12" md="6" class="py-0 px-0">
                <v-card-title>
                  {{ `${$_t("attribute.end")}: ${$_date(event.end, "long")}` }}
                </v-card-title>
              </v-col>
              <v-col col="12" md="12" class="py-0 px-0">
                <v-card-title>
                  {{
                    `${$_t("attribute.classroomName")}: ${event.classroomName}`
                  }}
                </v-card-title>
              </v-col>
            </v-row>
          </v-container>
          <div class="position-relative my-3" v-if="event.students">
            <v-divider> </v-divider>
            <span class="centered-axis-x white" style="top: -12px">{{
              $_t("attribute.students")
            }}</span>
          </div>
          <v-list v-if="event.students">
            <v-list-item
              v-for="(student, index) in event.students"
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    `${$_t("attribute.fullName")} : ${student.studentFullName}`
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showDetails = false">
              {{ $_t("attribute.close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { event_colors } from "@/packages/admin/schema/calender/COLORS";
export default {
  name: "StudentsTimes",
  data() {
    return {
      focus: "",
      showDetails: false,
      event: null,
      event_colors,
    };
  },
  computed: {
    ...mapGetters({
      studentsEvents: "class/getStudentEvents",
      students: "admin/get_users",
    }),
    getTitle() {
      return this.$refs.studentCalendar.title;
    },
    selectedStudents: {
      get() {
        return this.$store.getters["class/getSelectedStudents"] || null;
      },
      set(value) {
        this.$store.commit("class/updateSelectedStudents", value);
      },
    },
  },
  methods: {
    async initStudents({ search = null }) {
      await this.$actions.getUsers({
        role: "Student",
        all: true,
        dispatch: "admin/setUsers",
        search,
      });
    },
    async getNotAvailable() {
      await this.$actions.getNotAvailableRoomsAndTimes({
        teacherPublicKey: null,
      });
    },
    async initTimes() {
      await this.$actions.getNotAvailableTimesOfStudent({
        publicKeys: this.selectedStudents.map((s) => s.publicKey),
      });
    },
    eventDetails(event) {
      console.log(event);
      this.event = event;
      this.showDetails = true;
    },
    getEventColor(event) {
      return event.color;
    },
    prev() {
      this.$refs.studentCalendar.prev();
    },
    next() {
      this.$refs.studentCalendar.next();
    },
    setToday() {
      this.focus = "";
    },
  },
  async mounted() {
    await this.getNotAvailable();
    await this.initStudents({});
  },
};
</script>

<style scoped></style>
