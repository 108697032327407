<template>
  <div style="width: 100%; padding: 10px">
    <v-row>
      <v-col cols="12" md="4">
        <v-select
          hide-details
          outlined
          :label="$_t('attribute.specification')"
          :items="specifications"
          v-model="specification"
          multiple
          item-text="title"
          return-object
        >
          <template v-slot:prepend-item>
            <div class="px-3 py-2">
              <v-text-field
                outlined
                :label="$_t('attribute.search')"
                dense
                hide-details
                @input="initSpecifications"
                v-model="specificationSearch"
              />
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" md="8">
        <v-select
          outlined
          :label="$_t('attribute.add_lesson_to_course')"
          multiple
          :item-text="(item) => `${item.name} | ${item.subTitle}`"
          hide-details
          return-object
          v-model="lessons"
          :items="lessonsList"
        >
          <template v-slot:selection="{ index, item }">
            <v-chip color="primary" v-if="index < 4" class="my-2">
              <span>{{
                truncateString(`${item.name} | ${item.subTitle}`, 12)
              }}</span>
            </v-chip>
            <span v-if="index === 4" class="grey--text text-caption">
              (+{{ lessons.length - 4 }} others)
            </span>
          </template>
          <template v-slot:prepend-item>
            <div class="px-3 py-2">
              <v-text-field
                outlined
                :label="$_t('attribute.search')"
                dense
                hide-details
                @input="initLessons({ search: $event })"
              />
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        class="bordering"
        style="max-height: 500px; overflow-y: auto"
      >
        <v-row class="blue lighten-4">
          <v-col cols="5" class="pl-3 blue--text">{{
            $_t("attribute.lessonName")
          }}</v-col>
          <v-col cols="3" class="pl-3 blue--text">{{
            $_t("attribute.teachHour")
          }}</v-col>
          <v-col cols="4" class="pl-3 blue--text">{{
            $_t("attribute.actions")
          }}</v-col>
        </v-row>
        <Container
          @drop="onDrop"
          drag-handle-selector=".column-drag-handle"
          lock-axis="y"
        >
          <Draggable
            v-for="(item, index) in lessons"
            :key="item.id"
            class="row d-flex border-bottom"
          >
            <v-col cols="5" class="d-flex align-center pl-0">
              <div class="ml-3">
                {{ index + 1 }}
              </div>
              <div class="d-flex flex-column ml-5">
                <div class="font-weight-bold">
                  {{ item.name }}
                  <!--                                  {{-->
                  <!--                                    item.description-->
                  <!--                                      ? `| ${item.description}`-->
                  <!--                                      : null-->
                  <!--                                  }}-->
                  {{
                    item.description
                      ? `| ${truncateString(item.description, 20)}`
                      : null
                  }}
                </div>
                <div class="text--disabled">
                  {{ item.title }} | {{ item.subTitle }} |
                  {{ $_t(`attribute.${item.level}`) }}
                </div>
              </div>
            </v-col>
            <v-col cols="3" class="d-flex justify-center align-center">
              <div class="d-flex justify-center align-baseline">
                <v-text-field
                  type="number"
                  style="width: 100px"
                  dense
                  solo
                  :label="$_t('attribute.hours')"
                  :value="item.teachingTimeInHour"
                  @input="
                    $store.commit('class/updateTeachingHour', {
                      ...item,
                      teachingTimeInHour: $event,
                    })
                  "
                />
                <!--                                <v-chip outlined>-->
                <!--                                  {{ `${item.teachingTimeInHour} hours` }}-->
                <!--                                </v-chip>-->
              </div>
            </v-col>
            <v-col cols="4">
              <div class="d-flex justify-center align-baseline">
                <span>
                  <v-btn @click="deleteLesson(index)" text fab>
                    <v-icon color="red">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </span>
                <span class="column-drag-handle text--disabled">
                  <v-icon>mdi-menu</v-icon>
                </span>
              </div>
            </v-col>
          </Draggable>
        </Container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { applyDrag } from "@/packages/admin/tools/helpers";
import { mapGetters } from "vuex";
import { Container, Draggable } from "vue-dndrop";

export default {
  name: "LessonsList",
  components: { Container, Draggable },
  computed: {
    ...mapGetters({
      specifications: "admin/get_specifications",
      lessonsList: "admin/get_lessons",
      baseType: "class/getBaseType",
    }),
    lessons: {
      get() {
        return this.$store.getters["class/getLessons"] || [];
      },
      set(value) {
        console.log(value);
        this.$store.commit("class/updateLessons", value);
      },
    },
    specification: {
      get() {
        return this.$store.getters["class/getSpecification"] || null;
      },
      set(value) {
        this.$store.commit("class/updateSpecification", value);
      },
    },
  },
  data() {
    return {
      specificationSearch: "",
    };
  },
  watch: {
    specification: async function (val) {
      const lessons = val.map((s) => s.lessons).flat();
      console.log(lessons);
      await this.$store.dispatch("class/setLessons", lessons);
    },
  },
  methods: {
    async initSpecifications() {
      await this.$actions.getSpecifications({
        all: true,
        dispatch: "admin/setSpecifications",
        baseType: this.baseType,
        search: this.specificationSearch,
      });
    },
    async initLessons({ search = null }) {
      await this.$actions.getLessons({
        all: true,
        dispatch: "admin/setLessons",
        search,
        baseType: this.baseType,
      });
    },
    onDrop(dropResult) {
      this.lessons = applyDrag(this.lessons, dropResult);
    },
    deleteLesson(index) {
      this.$store.commit("class/deleteLesson", index);
    },
  },
  mounted() {
    this.initSpecifications();
    this.initLessons({});
  },
};
</script>

<style scoped></style>
