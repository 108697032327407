var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-sheet',{attrs:{"height":"100"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-select',{attrs:{"hide-details":"","outlined":"","label":"Selected teacher","dense":"","items":_vm.teachers,"clearable":"","item-text":(item) =>
                 `${item.fullName} | ${item.nickName}| ${
                   item.state
                 } | ${item.baseType.map((t) => _vm.$_t(`attribute.${t}`))}`,"item-value":"publicKey"},on:{"input":_vm.getClassroomsTimes},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"px-3 py-2"},[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$_t('attribute.search'),"dense":"","hide-details":""},on:{"input":_vm.initTeachers},model:{value:(_vm.searchTeacher),callback:function ($$v) {_vm.searchTeacher=$$v},expression:"searchTeacher"}})],1)]},proxy:true}]),model:{value:(_vm.teacherPublicKey),callback:function ($$v) {_vm.teacherPublicKey=$$v},expression:"teacherPublicKey"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"hide-details":"","outlined":"","label":"Filter teachers","dense":"","multiple":"","items":_vm.teachers,"clearable":"","item-text":(item) =>
                 `${item.fullName} | ${item.nickName}| ${
                   item.state
                 } | ${item.baseType.map((t) => _vm.$_t(`attribute.${t}`))}`,"item-value":"publicKey"},on:{"change":function($event){return _vm.initTimes({})}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"px-3 py-2"},[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$_t('attribute.search'),"dense":"","hide-details":""},on:{"input":_vm.initTeachers},model:{value:(_vm.searchTeacher),callback:function ($$v) {_vm.searchTeacher=$$v},expression:"searchTeacher"}})],1)]},proxy:true}]),model:{value:(_vm.teacherPublicKeys),callback:function ($$v) {_vm.teacherPublicKeys=$$v},expression:"teacherPublicKeys"}})],1)],1),_c('v-row',[_c('EventsStateColor',{attrs:{"show-new":false}})],1)],1)],1),_c('v-sheet',{attrs:{"height":"500"}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","events":_vm.mixEvents,"locale":_vm.$i18n.locale,"event-color":_vm.getEventColor,"type":"week"},on:{"change":_vm.updateRange},scopedSlots:_vm._u([{key:"event",fn:function({ event, timed, eventSummary }){return [_c('div',{staticStyle:{"height":"90%"}},[_c('div',{staticClass:"v-event-draggable"},[(event.classroomNumber)?_c('div',[_vm._v(" "+_vm._s(`${event.classroomNumber}`)+" ")]):_vm._e()]),(event.lesson || event.baseType)?_c('div',{staticClass:"v-event-draggable"},[_vm._v(" "+_vm._s(`${ event?.lesson.map((l) => `${_vm.$_t(`attribute.${l}`)}`) || event?.baseType.map((l) => `${_vm.$_t(`attribute.${l}`)}`) }`)+" "),_c('br'),(event.teacherName)?_c('div',[_vm._v(" "+_vm._s(`${_vm.$_t("attribute.teacher")}: ${event.teacherName}`)+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"v-event-draggable"},[_c({ render: eventSummary },{tag:"component"})],1)]),(timed && typeof event.availableTimeId === 'number')?_c('div',{staticClass:"v-event-drag-bottom",on:{"mousedown":function($event){$event.stopPropagation();return _vm.extendBottom(event)}}}):_vm._e()]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }